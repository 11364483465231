import * as React from 'react'
import { useEffect, useState } from 'react'
import {
	Button,
	Flex,
	Tab,
	TabList,
	Tabs,
	Text,
	useToast
} from '@chakra-ui/react'
import FlipMove from 'react-flip-move'
import LeaderboardItem from '../components/leaderboardItem'
import {
	useInfiniteQuery,
	useQuery,
	useQueryClient,
	useMutation
} from '@tanstack/react-query'
import { getPublic } from '../services/leaderboards'
import { navigate } from 'gatsby'
import io from 'socket.io-client'
import ClosePLSVG from '../icons/close-pl.svg'
import LeaderboardTabItem from '../components/leaderboardTabItem'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Spinner from '../components/spinner'
import {
	calculateHeightValue,
	calculateWidthValue
} from '../helpers/dimensionTransformer'
import { errorToastOptions } from '../helpers'
import { getPublicTeamCount } from '../services/team'
import { checkCustomerStatus } from '../services/credits'
import Drawer from '../components/drawer'
import { updateGroupMemberByRfid } from '../services/groupMember'
import Modal from '../components/modal'

const socket = io.connect(process.env.REACT_APP_WEBSOCKET_URL)

const PublicLeaderboardPage = ({ games, rfid, setRFID }) => {
	const queryClient = useQueryClient()
	const [game, selectGame] = useState('0')
	const [tab, selectTab] = useState(0)
	const [profile, setProfile] = useState(null)
	const [groupMember, setGroupMember] = useState(null)
	const [group, setGroup] = useState(null)
	const [team, setTeam] = useState(null)
	const [data, setData] = useState([])
	const toast = useToast()

	/**
	 * QUERIES
	 */

	const groupTeamsQuery = useQuery(
		['groupTeamsCount', team?.groupId],
		getPublicTeamCount,
		{ enabled: !!team?.groupId }
	)

	const customerStatusQuery = useQuery(
		['customerStatus', rfid, group?.eventId?.toString()],
		checkCustomerStatus,
		{ enabled: !!rfid && !!group?.eventId }
	)

	const leaderboardsQuery = useInfiniteQuery(
		[
			'leaderboards-interactive',
			30,
			tab,
			game,
			'group',
			team?.groupId,
			rfid
		],
		getPublic,
		{
			getNextPageParam: (lastPage) => {
				const { page, maxPage } = lastPage?.pagination || {}
				return page < maxPage ? page + 1 : null
			},
			onError: () => {
				toast({
					description:
						'Unable to find your group, please contact one of the team.',
					...errorToastOptions,
					position: 'bottom',
					onCloseComplete: () => {
						handleClose()
					}
				})
			}
		}
	)

	const [sentryRef] = useInfiniteScroll({
		loading: leaderboardsQuery.isLoading,
		hasNextPage: leaderboardsQuery.hasNextPage || false,
		onLoadMore: async () => {
			console.log('load more...')
			await leaderboardsQuery.fetchNextPage()
		},
		disabled: leaderboardsQuery.status === 'error',
		rootMargin: '0px 0px 1000px 0px'
	})

	/**
	 * CONSTANTS
	 */

	const creditsRemaining = groupMember?.creditsRemaining

	const userCanPlay = customerStatusQuery?.data?.canPlay

	const showBonusCreditsNotification =
		groupMember?.showBonusCreditsNotification

	const filteredGames = React.useMemo(() => {
		const groupMemberCredits = groupMember?.credits

		if (!groupMemberCredits) return []

		return games.filter((game) => {
			const gameCreditId = String(Number(game.node?.frontmatter?.id) + 1)

			return (
				game.node?.frontmatter?.id !== '0' &&
				groupMemberCredits[gameCreditId]
			)
		})
	}, [groupMember?.credits, games])

	const groupHasMultipleTeams =
		!groupTeamsQuery.isError &&
		!groupTeamsQuery.isLoading &&
		groupTeamsQuery.data.count > 1

	/**
	 * MUTATIONS
	 */

	const updateGroupMemberMutation = useMutation(updateGroupMemberByRfid, {
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: ['leaderboards-interactive']
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})

	const handleCloseBonusCreditsNotification = () => {
		if (rfid) {
			updateGroupMemberMutation.mutate({
				rfid,
				body: { showBonusCreditsNotification: false }
			})
		}

		return
	}

	const handleClose = React.useCallback(() => {
		setRFID(null)
		navigate('/public-leaderboard')
	}, [setRFID])

	/**
	 * EFFECTS
	 */

	useEffect(() => {
		const timeout = setTimeout(() => {
			handleClose()
		}, 60000)

		return () => {
			clearInterval(timeout)
		}
	}, [handleClose])

	useEffect(() => {
		if (leaderboardsQuery.data?.pages) {
			const _data = []
			let _profile = null
			let _team = null
			let _groupMember = null
			let _group = null
			leaderboardsQuery.data.pages.forEach((page) => {
				_data.push(...page.leaderboard)
				_profile = page.profile || {}
				_team = page.userTeam || {}
				_groupMember = page.groupMember || {}
				_group = page.group || {}
			})
			setProfile(_profile)
			setGroupMember(_groupMember)
			setTeam(_team)
			setData(_data)
			setGroup(_group)
		} else {
			setData(null)
		}
	}, [leaderboardsQuery.data])

	useEffect(() => {
		socket.on('connect', () => {
			console.log('connected')
		})

		socket.on('leaderboards', (data) => {
			console.log('update leaderboards', data)
			queryClient.invalidateQueries(['leaderboards-interactive'])
		})

		socket.on('disconnect', () => {
			console.log('disconnected')
		})

		return () => {
			socket.off('connect')
			socket.off('disconnect')
			socket.off('leaderboards')
		}
	}, [queryClient])

	if (!profile) {
		return (
			<Flex
				flex="1"
				direction="column"
				justifyContent="center"
				alignItems="center"
				position="relative"
			>
				<Button
					position="absolute"
					top={calculateHeightValue(120)}
					right={calculateWidthValue(120)}
					variant="icon"
					onClick={handleClose}
				>
					<ClosePLSVG
						width={calculateWidthValue(220)}
						height={calculateWidthValue(220)}
					/>
				</Button>
			</Flex>
		)
	}

	return (
		<Flex
			flex="1"
			direction="column"
			justifyContent="center"
			alignItems="center"
			position="relative"
			minH="100vh"
		>
			<Button
				position="absolute"
				top={calculateHeightValue(120)}
				right={calculateWidthValue(120)}
				variant="icon"
				onClick={handleClose}
			>
				<ClosePLSVG
					width={calculateWidthValue(220)}
					height={calculateWidthValue(220)}
				/>
			</Button>
			<Flex
				mt={calculateHeightValue(160)}
				minH={calculateHeightValue(234)}
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Text variant="namePL" color="white">
					{tab === 0
						? profile?.nickname || ' '
						: team?.name || profile?.nickname || ' '}
				</Text>
				<Text variant="versusPL" color="white" mt="-4px">
					vs My Booking
				</Text>
			</Flex>
			<Flex flex="1" direction="column" alignItems="center" w="100%">
				<Flex
					w="100%"
					mt="0"
					mx="0"
					mb={calculateHeightValue(50)}
					pl={calculateWidthValue(80)}
					pr={calculateWidthValue(20)}
					direction="row"
					overflowX="scroll"
					overflowY="hidden"
				>
					{games.map((item, index) => (
						<LeaderboardTabItem
							key={index}
							selectTab={selectGame}
							isActive={
								game === (item.node?.frontmatter?.id || '-1')
							}
							game={item.node?.frontmatter || {}}
							theme="PL"
						/>
					))}
				</Flex>
				<Flex
					flex="1"
					direction="column"
					alignItems="center"
					// w="100%"
					pb={calculateHeightValue(150)}
					position="relative"
				>
					{groupHasMultipleTeams && (
						<Tabs
							w={calculateWidthValue(1460)}
							variant="soft-rounded"
							onChange={(idx) => selectTab(idx)}
						>
							<TabList
								w={calculateWidthValue(926)}
								p={calculateWidthValue(6)}
								// h={calculateHeightValue(67)}
							>
								<Tab
									key="player"
									w={calculateWidthValue(437)}
									h={calculateHeightValue(174)}
									mt={calculateHeightValue(14)}
									mb={calculateHeightValue(14)}
									ml={calculateWidthValue(14)}
									fontSize={calculateWidthValue(55)}
									lineHeight={calculateWidthValue(78)}
								>
									Player
								</Tab>
								<Tab
									key="team"
									w={calculateWidthValue(437)}
									h={calculateHeightValue(174)}
									mt={calculateHeightValue(14)}
									mb={calculateHeightValue(14)}
									ml={calculateWidthValue(14)}
									fontSize={calculateWidthValue(55)}
									lineHeight={calculateWidthValue(78)}
								>
									Team
								</Tab>
							</TabList>
						</Tabs>
					)}
					{(data || []).length > 0 && (
						<FlipMove
							enterAnimation="fade"
							leaveAnimation="fade"
							maintainContainerHeight="true"
						>
							{(data || []).map((item, index) => {
								const key = `${index}-${item?.groupId}-leaderboard-item`
								return (
									<LeaderboardItem
										key={key}
										item={item}
										isTeam={tab === 1}
										gamesPlayed={item.games}
										active={
											item.playerId ===
												(profile?.id || '-') ||
											item.teamId === (team?.id || '-')
										}
										number={index + 1}
										theme="PL"
									/>
								)
							})}
						</FlipMove>
					)}
					{(leaderboardsQuery.isLoading ||
						leaderboardsQuery.hasNextPage) && (
						<Flex ref={sentryRef}>
							<Spinner />
						</Flex>
					)}
				</Flex>

				{userCanPlay && (
					<Drawer
						creditsRemaining={creditsRemaining}
						games={filteredGames}
						isLeaderboardView={true}
					/>
				)}
			</Flex>
			<Modal
				opened={showBonusCreditsNotification}
				onClose={handleCloseBonusCreditsNotification}
				offsetLeft={false}
				title={
					<>
						Want
						<br />
						more?
					</>
				}
				description={
					<>
						<Text variant="m" px="10px" textAlign="center" mb={4}>
							You got it!
						</Text>
						<Text variant="m" px="10px" textAlign="center" mb={4}>
							You've received extra game credits so you can carry
							on playing and improve your previous scores.
						</Text>
						<Text variant="m" px="10px" textAlign="center">
							Credits are automatically reloaded when you complete
							a round of 9 games and we'll continue to reload
							until your session expires.
						</Text>
					</>
				}
				isPink={true}
				primaryBtn={
					<Button
						variant="primary"
						onClick={handleCloseBonusCreditsNotification}
					>
						Let's go
					</Button>
				}
			></Modal>
		</Flex>
	)
}

export default PublicLeaderboardPage
